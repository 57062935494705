<template>
  <div>
    <div class="product-content">
      <div class="lacking">
        <h3 class="title">赢信汇通品牌制动鼓</h3>
        <h6 class="content">
          公司集研发、生产、销售、服务为一体，专业生产：商用车和乘用车制动鼓、轮毂、刹车盘、减速器、差速器、半挂和驱动车桥总成及铁路机车、动车组、城轨地铁轮对等重要制动零部件。
          四川赢信汇通实业有限公司成立于2017年8月，位于成都市大邑县工业园区，占地161亩。项目总投资1.5亿元，于2017年11月28号开工建设，已于2018年6月建成投产，2020年实现销售收入1.5亿元。预计2021年可实现销售收入2亿元。
        </h6>
        <el-divider class="el-divider-active"></el-divider>
        <h4 style="color: #333">制动鼓</h4>
        <div class="tit">
          1.采用高碳合金灰铸铁材料，更好的铸造性、减振性、耐磨性、切削加工性和较低的缺口敏感性。
        </div>
        <div class="tit">
          2.采用高碳合金HT250或以上材质，使得制动鼓机械强度较高，不易爆裂，不易磨损。
        </div>
        <div class="tit">
          3.合金材料的加入(如铜、铬、锰、锡等)使得机械性能大增，机械强度、热耐磨性能、抗热疲劳性能等均比未加合金元素的制动鼓强度高40MPa以上。
        </div>
        <div class="tit">
          4.
          全自动壳型生产线，所得制动鼓外观光洁、外表致密、材质均匀、壁厚均匀、性能稳定、使用可靠。
        </div>

        <div class="lacking-bottom">
          <div class="showitem">
            <ul>
              <li v-for="(item, index) in imglist" :key="index">
                <img :src="item.img | image" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <!-- <h3 class="title">安达鼓品牌制动鼓</h3>
        <div class="lacking-bottom">
          <div
            class="lacking-bottom-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="lacking-bottom-item-img">
              <img :src="item.img" alt class="box_img" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      imglist: [
        {
          img: "yxht8.png",
        },
        {
          img: "yxht9.png",
        },
        {
          img: "yxht10.png",
        },
        {
          img: "yxht11.png",
        },
        {
          img: "yxht12.png",
        },
        {
          img: "yxht13.png",
        },
        {
          img: "yxht14.png",
        },
        {
          img: "img8.png",
        },
        {
          img: "img9.png",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>

<style scoped lang='scss'>
.product-content {
  width: 1240px;
  margin: 20px auto;
  background-color: #fff;
}
//传统管理模式不足
.lacking {
  height: 100%;
  padding: 20px 0;
  color: #14679f;
  .lacking-bottom {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    .showitem {
      margin-top: 25px;
    }

    .showitem ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .showitem ul li {
      overflow: hidden;
      margin-top: 10px;
      width: 400px;
      height: 250px;
    }
    .showitem ul li img {
     width: 400px;
      height: 200px;
      margin: 20px;
      transition: all 0.5s;
    }

    .showitem ul li:hover img {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}
.content {
  padding-top: 20px;
  color: #14679f;
  font-size: 14px;
  line-height: 30px;
}
.tit {
  font-size: 14px;
  color: #333;
  line-height: 30px;
}
</style>